import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: '#0d3863',
                secondary: '#4296EB',
                background: '#b0bec5',
                menu: '#424242',
                danger: '#EB6259',
                warning: '#F3B61F'
            },
        },
    },
});