import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// Containers

const MainView = () =>
    import ('@/components/globals/template/mainView/main.view')
const CambioAppView = () =>
    import ('@/components/globals/cambioApp/cambioApp.view')
const usuariosView = () =>
    import ('@/components/usuarios/usuarios.view')
const usuarioView = () =>
    import ('@/components/usuarios/usuario.view')

const listaEmpresasView = () =>
    import ('@/components/empresas/listaEmpresas.view')
const empresaView = () =>
    import ('@/components/empresas/empresa.view')

const listaEscuelasView = () =>
    import ('@/components/escuelas/listaEscuelas.view')
const escuelaView = () =>
    import ('@/components/escuelas/escuela.view')

const permisosView = () =>
    import('@/components/permisos/permisos.view')

// const profesorView = () =>
//     import('@/components/usuarios/vistaProfesor.view')
// const alumnoView = () =>
//     import('@/components/usuarios/vistaAlumno.view')

const avisosView = () =>
    import('@/components/avisos/avisos.view')


export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [{
            path: '/',
            redirect: '/usuarios',
            name: 'MainTemplate',
            component: MainView,
            props: { app: 'master' },
            children: [
                { path: 'usuarios', name: 'usuarios', component: usuariosView },
                { path: 'usuario/:idUsuario', name: 'vistaUsuario', component: usuarioView },
                // { path: 'main', name: 'Inicio', component: MainView },
                { path: 'escuelas', name: 'Escuelas', component: listaEscuelasView },
                { path: 'escuela/:idEscuela', name: 'Escuela', component: escuelaView },
                { path: 'empresas', name: 'Empresas', component: listaEmpresasView },
                { path: 'empresa/:idEmpresa', name: 'Empresa', component: empresaView },
                { path: 'permisos', name: 'Permisos', component: permisosView },
                // { path: 'alumno/:idAlumno', name: 'vistaAlumno', component: alumnoView },
                { path: 'avisos', name: 'Avisos', component: avisosView },
            ]
        },
        { path: '/cambioApp/:token', name: 'Redirección', component: CambioAppView },
        {
            //Cualquier ruta que no exista, se redireccionará al main
            path: '*',
            redirect: '/usuarios',
            // name: 'Not found',
            // component: Page404
        }
    ]
})