import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import router from './router'
import { store } from './store/store';
import Notifications from 'vue-notification'
import Notificaciones from './plugins/notificaciones'
import ValidateResponse from './plugins/validateResponse';

import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(Notificaciones)
Vue.use(ValidateResponse, router)


new Vue({
    vuetify,
    router,
    store,
    iconfont: 'fa',
    render: h => h(App)
}).$mount('#app')