const state = {
    sessionToken: null,
    userName: 'Unknown user',
    userAvatar: null,
    userId: null,
    apps: [],
    tipoSesion: [],
    timezone: 'America/Mexico_City',
    menu:[],
}

const getters = {
    sessionToken: state => {
        return state.sessionToken;
    },
    userName: state => {
        return state.userName;
    },
    userAvatar: state => {
        return state.userAvatar;
    },
    userId: state => {
        return state.userId;
    },
    apps: state => {
        return state.apps;
    },
    tipoSesion: state => {
        return state.tipoSesion;
    },
    timezone: state => {
        return state.timezone;
    },
    menu:state =>{
        return state.menu;
    }
}

const actions = {
    setSessionToken(context, payload) {
        context.commit('setSessionTokenHandler', payload);
    },
    setUserName(context, payload) {
        context.commit('setUserNameHandler', payload);
    },
    setUserAvatar(context, payload) {
        context.commit('setUserAvatarHandler', payload);
    },
    setUserId(context, payload) {
        context.commit('setUserIdHandler', payload);
    },
    setApps(context, payload) {
        context.commit('setAppsHandler', payload);
    },
    setTipoSesion(context, payload) {
        context.commit('setTipoSesionHandler', payload);
    },
    setTimezone(context, payload) {
        context.commit('setTimezoneHandler', payload);
    },
    setMenu(context, payload) {
        context.commit("setMenuHandler", payload);
    },
}

// mutations
const mutations = {
    setSessionTokenHandler(state, token) {
        state.sessionToken = token;
    },
    setUserNameHandler(state, name) {
        state.userName = name;
    },
    setUserAvatarHandler(state, userAvatar) {
        state.userAvatar = userAvatar;
    },
    setUserIdHandler(state, userId) {
        state.userId = userId;
    },
    setAppsHandler(state, apps) {
        state.apps = apps;
    },
    setTipoSesionHandler(state, tipoSesion) {
        state.tipoSesion = tipoSesion;
    },
    setTimezoneHandler(state, timezone) {
        state.timezone = timezone;
    },
    setMenuHandler(state, menu) {
        state.menu = menu;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}